.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.modal {
    position:fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    z-index: 50;
    overflow: hidden;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem 2rem 2rem 2rem;
    text-align: center;
}
  
.header {
    background: #4f005f;
    padding: 1rem;
}
  
.header h2 {
    margin: 0;
    color: white;
}
  
.content {
    padding: 1rem;
}
  
.actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
  
@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}  