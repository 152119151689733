.search {
    font-family: "Noto Serif", serif;
    font-size: 32px;
    font-weight: 300;   
    background-color: #D9D9D9;
    padding: 0.5rem 1rem 0.5rem 5rem;
    width: 400px;
    border-radius: 1rem;
    border: none;
}

.search::placeholder {
    opacity: 37%;
}

.search:focus {
    outline: 0.05em solid #AB8500;
}

.search-icon {
    height: 4rem;
    position: absolute;
    opacity: 40%;
    padding: 0 0.5rem 0.5rem 0.5rem;
}