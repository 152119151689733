.navbar-container {
    display: flex;
    gap: 40px;
    padding-left: 0;
    justify-self: center;
    margin: 0;
}

.navbar-item {
    list-style-type: none;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'Noto Serif', serif;
    font-size: 40px;
    font-weight: 300;
}

.active {
    color: #AB8500;
}

.separator {
    font-family: 'Bacasime Antique', serif;
    font-size: 48px;
    cursor: default;
    font-weight: 100;
}

a {
    color: #E0AF00;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}