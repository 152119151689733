.main-picture {
    max-height: 20rem;
    max-width: 20rem;
    padding: 2px;
    border: 2px solid black;
}

@media screen and (max-width: 480px) {
    .main-picture {
        height: 10rem;
    }
}

.artist {
    font-family: "Noto Serif", serif;
    font-size: 32px;
    font-weight: 300;
    white-space: nowrap;
}