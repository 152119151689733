.header-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    justify-items: center;
}

.line {
    border: 0;
    clear:both;
    display:block;
    width: 96%;               
    background-color:#6C6C6C;
    height: 1px;
}

.top {
    margin-top: 2rem;
}

.bottom {
    margin-bottom: 2rem;
}